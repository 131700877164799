<template>
  <v-container fluid align-center class="pt-15">
    <v-row class="mt-5 mx-5">
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="4">
                  <span class="font-weight-bold">Filtros:</span>
                </v-col>
                <v-col cols="8" class="text--secondary" >
                  <v-fade-transition leave-absolute>
                    <span v-if="open"></span>
                    <v-row v-else no-gutters style="width: 100%">
                      <v-col cols="6">
                        <span class="font-weight-bold">Tipo de periodo:</span> <span style="color:#5271ff;">{{ typePeriod }}</span>
                      </v-col>
                      <v-col cols="6">
                        <span class="font-weight-bold">Periodo:</span> <span style="color:#5271ff;">{{ record.periodo }}</span>
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around" no-gutters>
                <v-col cols="6">
                  <v-select v-model="record.tipoPeriodo" label="Tipo de periodo" :items="catalogues.tipos_periodos" item-text="text" item-value="value"
                  prepend-icon="mdi-calendar-check" dense class="ml-5 mr-2" style="max-width: 290px;" :disabled="loading" @change="setDateRecord()"></v-select>
                </v-col>
                <v-col cols="6">
                  <template v-if="record.tipoPeriodo === 'mensual'">
                    <v-menu ref="menuRecord" v-model="record.menu" :close-on-content-click="false" :return-value.sync="record.periodo" 
                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="record.periodo" label="Periodo" v-bind="attrs" dense v-on="on" prepend-icon="mdi-calendar" class="mx-2" style="max-width: 190px;" :disabled="loading" readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="record.periodo" type="month" locale="es" style="background-color: #f1f1f1">
                        <v-spacer></v-spacer>
                        <v-btn text color="blue-grey" @click="record.menu = false">
                          Cancelar
                        </v-btn>
                        <v-btn text color="success" @click="$refs.menuRecord.save(record.periodo); getData()">
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </template>
                  <template v-else-if="record.tipoPeriodo === 'anual'">
                    <v-select v-model="record.periodo" label="Periodo" :items="catalogues.years" item-text="text" item-value="value"
                    prepend-icon="mdi-calendar" dense class="mx-2" style="max-width: 190px;" light hide-details :disabled="loading" @change="getData()"></v-select>
                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!loading" cols="12">
        <v-card min-height="250" min-width="300" class="pa-5" style="display: flex; align-items: center;"> 
          <v-row>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black;">
                  Comparado con la industria
                </v-card-text>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(8 124 23); font-weight: bold;" class="pt-10">
                  {{kpis.comparado_industria.valor}}
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  {{kpis.comparado_industria.subtitulo}}
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Promedio: {{kpis.comparado_industria.promedio}} tCO2e
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black;">
                  Comparado con tu objetivo
                </v-card-text>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{kpis.comparado_objetivo.valor}}
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  {{kpis.comparado_objetivo.subtitulo}}
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Objetivo: {{kpis.comparado_objetivo.objetivo}} tCO2e
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black;">
                  Consumo energético
                </v-card-text>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(215 69 69); font-weight: bold;" class="pt-10">
                  {{kpis.consumo_energetico.valor}}
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  {{kpis.consumo_energetico.subtitulo}}
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Pasado: {{kpis.consumo_energetico.consumo_pasado}} kWh
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="card">
              <v-card id="card-cards" min-height="200" min-width="300" max-width="320" href="https://planetacarbononeutral.org/elige-cantidad-a-compensar/" target="_blank">
                <v-card-text class="text-center font-weight-bold" style="font-size: 20px; color: black;">
                  Emisiones compensadas
                </v-card-text>
                <v-card-subtitle style="font-size: 45px; text-align: center; color: rgb(69 73 215); font-weight: bold;" class="pt-10">
                  {{kpis.compensaciones.valor}}
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  tCO2e
                </v-card-text>
                <v-card-text style="text-align: center; font-weight: bold; font-size: 18px;" class="pa-0">
                  Equivalente: {{kpis.compensaciones.equivalencia}} árboles
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12">
        <v-card>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">
            Huella de Carbono {{hdc.empresa}}
          </v-card-title>
          <v-row>
            <v-col>
              <div class="pt-12 pb-5">
                <v-card-subtitle style="font-size: 50px; text-align: center; color: rgb(137 60 219); font-weight: bold;" class="pt-15">
                  {{hdc.total}}
                </v-card-subtitle>
                <v-card-text style="text-align: center; font-weight: bold; color: rgb(0,0,0);" class="pb-3">
                  tCO2e
                </v-card-text>
              </div>
              <v-card-text style="text-align: center; font-weight: bold; font-size: 12px;" class="pt-10">
                {{hdc.subtitulo}}
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-title>Alcance 1</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(69 73 215); font-weight: bold;" class="pt-2">
                {{hdc.alcance1}}<span style="color: rgb(0,0,0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
              <v-card-title>Alcance 2</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(26 108 44); font-weight: bold;" class="pt-2">
                {{hdc.alcance2}}<span style="color: rgb(0,0,0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
              <v-card-title>Alcance 3</v-card-title>
              <v-card-subtitle style="font-size: 50px; color: rgb(185 77 44); font-weight: bold;" class="pt-2">
                {{hdc.alcance3}}<span style="color: rgb(0,0,0); font-size: 14px"> tCO2e</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Emisiones por categoría</v-card-title>
          <div class="container">
            <Doughnut :chartData="chartData.Doughnut.data" :options="chartData.Doughnut.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5 mx-5">
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%">
          <v-card-title class="justify-center font-weight-bold">Emisiones a través del tiempo</v-card-title>
          <div class="container">
            <Bar :chartData="chartData.Bar.data" :options="chartData.Bar.options"/>
          </div>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col v-show="!loading" cols="12" md="6">
        <v-card max-height="500" min-width="300" height="100%"
        style="display: flex !important; flex-direction: column;">
          <v-card-title class="justify-center font-weight-bold">Medidas de reducción</v-card-title>
          <v-card-text style="flex-grow: 1; overflow: auto;">
            <v-list-item-group v-model="selectReductionMeasure" active-class="success--text">
              <template v-for="(item, idx) in reductionMeasures">
                <v-list-item :key="idx" @click="dialogs.reductionMeasures = true">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.equipo"></v-list-item-title>
                       <v-list-item-subtitle v-if="item.energia" class="text--primary" v-text="item.energia.join(', ')"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.sugerencia"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.ahorro"></v-list-item-action-text>
                      <v-icon v-if="!item.comprometido" color="grey lighten-1">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="success">
                        mdi-check-circle
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="idx <= reductionMeasures.length - 1" :key="'div-' + idx"></v-divider>
              </template>
            </v-list-item-group>
          </v-card-text>
        </v-card>  
      </v-col>
      <v-col v-show="loading" cols="12" md="6">
        <v-card>
          <v-skeleton-loader type="list-item-two-line, image, table-tfoot"></v-skeleton-loader>
        </v-card> 
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-overlay absolute :value="record.loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Generado reporte...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <v-dialog v-model="dialogs.reductionMeasures" persistent max-width="390">
      <v-card style="margin-top: 0; background-color: white;">
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>Para comprometerse con la medida de reducción, favor de comunicarse con el administrador de SinCarbono. ¡Gracias!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :loading="loading" color="success" @click="cancelNotification()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/utils/services'
import moment from 'moment-timezone'
import Bar from '../Charts/Bar.js'
import Doughnut from '../Charts/Donut.js'
import downloadjs from 'downloadjs'
import _ from 'lodash'

export default {
  name: 'dashboardStandard',
  components: { Bar, Doughnut },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    errors: {
      kpis: false,
      hdc: false,
      emissionByCategory: false,
      emissionOverTime: false,
      reductionMeasures: false
    },
    msgError: '',
    catalogues: {
      tipos_periodos: [
        { text: 'Mensual', value: 'mensual' },
        { text: 'Anual', value: 'anual' }
      ],
      years: []
    },
    record: {
      loading: false,
      tipoPeriodo: 'mensual',
      periodo: '',
      menu: false
    },
    kpis: {
      comparado_industria: {
        promedio: '',
        subtitulo: '',
        valor: ''
      },
      comparado_objetivo: {
        objetivo: '',
        subtitulo: '',
        valor: ''
      },
      compensaciones: {
        equivalencia: '',
        valor: ''
      },
      consumo_energetico: {
        consumo_pasado: '',
        subtitulo: '',
        valor: ''
      }
    },
    hdc: {
      alcance1: '',
      alcance2: '',
      alcance3: '',
      empresa: '',
      subtitulo: '',
      total: ''
    },
    chartData: {
      Bar: {
        data: null,
        options: null 
      },
      Doughnut: {
        data: null,
        options: null 
      }
    },
    reductionMeasures: [],
    selectReductionMeasure: null,
    dialogs: {
      reductionMeasures: false,
    }
  }),
  mounted() {
    this.setCatYears()
    this.setDateRecord()
  },
  computed: {
    company() {
      return this.$store.state.general.company
    },
    typePeriod() {
      return _.capitalize(this.record.tipoPeriodo)
    },
    report() {
      return this.$store.state.general.report
    }
  },
  watch: {
    company: function (val) {
      this.getData()
    },
    report: function (val) {
      if (val) {
        this.getReportGeneral()
      }
    }
  },
  methods: {
    ...mapActions('general', [
      'setReport'
    ]),
    setCatYears() {
      let year = parseInt(moment().format('YYYY'))
      for (let i = 0; i < 5; i++) {
        this.catalogues.years.unshift((year - i).toString())
      }
    },
    setDateRecord() {
      if (this.record.tipoPeriodo === 'mensual') {
        this.record.periodo = moment().format('YYYY-MM')        
      }
      else if (this.record.tipoPeriodo === 'anual') {
        this.record.periodo = moment().format('YYYY')        
      }
      this.getData()
    },
    getKpisHDC() {
      this.loading = true
      this.axios.get(services.routes.kpisHDC + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo + 
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.kpis = response.data.data.kpi
          this.hdc = response.data.data.hdc
          this.hdc.empresa = 'de ' + this.hdc.empresa
        })
        .catch(error => {
          this.kpis = {
            comparado_industria: {
              valor: '-',
              subtitulo: 'Información no disponible',
              promedio: 0
            },
            comparado_objetivo: {
              valor: '-',
              subtitulo: 'Información no disponible',
              objetivo: 0
            },
            consumo_energetico: {
              valor: '-',
              subtitulo: 'Información no disponible',
              consumo_pasado: 0
            },
            compensaciones: {
              valor: 0,
              equivalencia: 0
            }
          }
          this.hdc = {
            empresa: '',
            total: 0,
            alcance1: 0,
            alcance2: 0,
            alcance3: 0,
            subtitulo: 'No existe información de huella de carbono en el año pasado para el mismo periodo'
          }
          this.error = true
          this.errors.kpis = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getKpis() {
      this.loading = true
      this.axios.get(services.routes.kpis + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo + 
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.kpis = response.data.data
        })
        .catch(error => {
          this.kpis = {
            comparado_industria: {
              valor: '-',
              subtitulo: 'Información no disponible',
              promedio: 0
            },
            comparado_objetivo: {
              valor: '-',
              subtitulo: 'Información no disponible',
              objetivo: 0
            },
            consumo_energetico: {
              valor: '-',
              subtitulo: 'Información no disponible',
              consumo_pasado: 0
            },
            compensaciones: {
              valor: 0,
              equivalencia: 0
            }
          }
          this.error = true
          this.errors.kpis = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getHDC() {
      this.loading = true
      this.axios.get(services.routes.hdc + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.hdc = response.data.data
          this.hdc.empresa = 'de ' + this.hdc.empresa
        })
        .catch(error => {
          this.hdc = {
            empresa: '',
            total: 0,
            alcance1: 0,
            alcance2: 0,
            alcance3: 0,
            subtitulo: 'No existe información de huella de carbono en el año pasado para el mismo periodo'
          }
          this.error = true
          this.errors.hdc = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getEmissionByCategory() {
      this.loading = true
      this.axios.get(services.routes.emissionByCategory + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo + 
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.chartData.Doughnut.data = response.data.data
          this.chartData.Doughnut.options =  {
            responsive: true,
            maintainAspectRatio: false
          }
        })
        .catch(error => {
          this.chartData.Doughnut.data = {
            labels: [],
            datasets: [
              {
                data: []
              }
            ]
          }
          this.chartData.Doughnut.options =  {
            responsive: true,
            maintainAspectRatio: false
          }
          this.error = true
          this.errors.emissionByCategory = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getEmissionOverTime() {
      this.loading = true
      this.axios.get(services.routes.emissionOverTime + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo + 
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.chartData.Bar.data = response.data.data
          this.chartData.Bar.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Toneladas de CO2e'
                }
              }]
            }
          }
        })
        .catch(error => {
          this.chartData.Bar.data = {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            datasets: [
              {
                label: 'Toneladas de CO2e',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              }
            ]
          }
          this.chartData.Bar.options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Toneladas de CO2e'
                }
              }]
            }
          }
          this.error = true
          this.errors.emissionOverTime = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getReductionMeasures() {
      this.loading = true
      this.axios.get(services.routes.reductionMeasures + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo +
      '&periodo=' + this.record.periodo)
        .then(response => {
          this.reductionMeasures = response.data.data
        })
        .catch(error => {
          this.reductionMeasures = []
          this.error = true
          this.errors.reductionMeasures = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    getData() {
      if (this.$store.state.general.company) {
        this.errors.kpis = false
        this.errors.hdc = false
        this.errors.emissionByCategory = false
        this.errors.emissionOverTime = false
        this.errors.reductionMeasures = false
        // this.getKpis()
        // this.getHDC()
        this.getKpisHDC()
        this.getEmissionByCategory()
        this.getEmissionOverTime()
        this.getReductionMeasures()
      }
    },
    cancelNotification() {
      this.selectReductionMeasure = null
      this.dialogs.reductionMeasures = false
    },
    getReportGeneral() {
      const source = _.get(this.chartData, 'Doughnut.data.labels', [])
      if (source.length == 1 && source[0] === 'Basura generada') {
        this.record.loading = true
        let params = {
          empresa: this.$store.state.general.company,
          tipo_periodo: this.record.tipoPeriodo,
          periodo: this.record.periodo,
          empresas: [this.$store.state.general.company],
          tipo_reporte: 'economia-circular'
        }
        this.axios.get(services.routes.ec.report, { params })
          .then(response => {
            let data = response.data.data
            let year = (this.record.tipoPeriodo === 'anual') ? this.record.periodo : this.record.periodo.split('-')[0]
            downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year + '.pdf' , 'application/pdf')
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.setReport({ val: false })
            this.record.loading = false
          })
      }
      else {
        this.record.loading = true
        this.axios.get(services.routes.report + '?empresa=' + this.$store.state.general.company + '&tipo_periodo=' + this.record.tipoPeriodo + 
        '&periodo=' + this.record.periodo + '&tipo_reporte=general')
          .then(response => {
            let data = response.data.data
            let year = (this.record.tipoPeriodo === 'anual') ? this.record.periodo : this.record.periodo.split('-')[0]
            downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year + '.pdf' , 'application/pdf')
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.setReport({ val: false })
            this.record.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
  .text-normal {
    text-transform: none !important;
  }
  .small {
    max-width: 600px;
  }
  .container.fill-height {
    justify-content: space-around;
    margin: auto;
  }
  .v-card {
    margin-top: 10px;
    background-color: rgb(227 227 227 / 28%)
  }
  #card-cards {
    margin-top: 10px;
  }
  .card {
    display: flex;
    justify-content: space-around;
  }
  .v-picker__actions {
    background-color: #f1f1f1;
  }
</style>
