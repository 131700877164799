import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  data: () => ({
    chartdata: {
      labels: ['Combustible', 'Vehículos', 'Electricidad', 'Transporte', 'Materia prima', 'Residuos'],
      datasets: [
        {
          backgroundColor: [
            'rgba(112, 0, 224)',
            'rgba(9, 194, 236)',
            'rgba(240, 200, 8)',
            'rgba(109, 100, 102)',
            'rgba(227, 219, 219)',
            'rgba(173, 215, 246)',
          ],
          data: [30, 20, 20, 10, 30, 45]
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
