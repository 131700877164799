import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  data: () => ({
    chartdata: {
      labels: ['Transporte', 'Electricidad', 'Materia prima', 'Residuos'],
      datasets: [
        {
          label: 'tCO2e',
          backgroundColor: [
            'rgba(112, 0, 224)',
            'rgba(9, 194, 236)',
            'rgba(240, 200, 8)',
            'rgba(109, 100, 102)',
          ],
          data: [30, 20, 20, 4]
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      }
    }
  }),
  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
