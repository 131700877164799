import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data: () => ({
    chartdata: {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
        {
          backgroundColor: [
            'rgba(112, 0, 224)',
            'rgba(9, 194, 236)',
            'rgba(240, 200, 8)',
            'rgba(109, 100, 102)',
            'rgba(227, 219, 219)',
            'rgba(173, 215, 246)',
            'rgba(135, 191, 255)',
          ],
          data: [40, 60, 10, 40, 15, 80, 5]
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Toneladas de CO2e'
          }
        }]
      }
    }
  }),
  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
